// React Component 
import styled from "styled-components";
import { Modal, Tabs, Tab } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setMetadata, setLeftCount, setTotalCount, setMintBalance, setWlBalance } from "../../../store/reducers/connectionReducer";

// Wallet Component 
import * as nearAPI from 'near-api-js';
import { burnNFT } from "../../../utilities/burn";
import { getProjectDetails } from "../../../utilities/api";
import { nftMintFromContract } from "../../../utilities/contract";

// Page Component 
import C from "../../../assets/img/picture/c2.png";
import G from "../../../assets/img/picture/gif.gif"
import Logo from "../../../assets/img/logo/logo.png";
import Enleap from "../../../assets/img/logo/logo_small.png";
import Web from "../../../assets/img/picture/web.png";
import Bg from "../../../assets/img/picture/bg.png";
import Telegram from "../../../assets/img/picture/telegram.png";
import Twitter from "../../../assets/img/picture/twitter.png";
import Discord from "../../../assets/img/picture/discord.png";
import Linkedin from "../../../assets/img/picture/linkedin.png";
import Website from "../../../assets/img/picture/website.png";
import CountDown from "../../../components/Elements/CountDown";
import Loader from "../../../assets/img/logo/loader_black.gif";
import ProgressBar from "../../../components/Elements/ProgressBar";

export default function Content() {

    // Wallet CONST
    const { id } = useParams();
    const dispatch= useDispatch();
    const [whitelisted, setWhitelisted] = useState(null);
    const [contractID, setContractID] = useState(null);
	const env= useSelector(state => state.env)
	const wallet = useSelector(state => state.wallet)
    const metadata = useSelector(state => state.metadata)
	const leftCount = useSelector(state => state.leftCount)
    const totalCount = useSelector(state => state.totalCount)
	const wlBalance = useSelector(state => state.wlBalance)
	const nearConfig = useSelector(state => state.nearConfig)
	const sentimentMeta = useSelector(state => state.sentiment)
    const near = new nearAPI.Near(useSelector(state => state.nearConfig))
    const userAccountId = useSelector(state => state.userAccountId)
    const account = new nearAPI.Account(near.connection, userAccountId);
	const mintBalance = useSelector(state => state.mintBalance)
    const userBalance = useSelector(state => state.userBalance)
	const isWalletConnected = useSelector(state => state.isWalletConnected)
    const [userStatus, setUserStatus] = useState(null);
    const [mintedToken, setMintedToken] = useState(null);
    const [publicMintDate, setPublicMintDate] = useState(null);
    const [txHashedToken, setTxHashedToken] = useState(null);
    const [ogMintDate, setOGMintDate] = useState(null);
    const [whitelistMintDate, setWhitelistMintDate] = useState(null);
    const [isWhitelistMintDate, setIsWhitelistMintDate] = useState(null);
    const [isPublicMintDate, setIsPublicMintDate] = useState(null);
    const [isOGMintDate, setIsOGMintDate] = useState(null);

    // Modal 
    const [mintType, setMintType] = useState(null);
    const [modal, setModal] = useState(false);
    const [promptModal, setPromptModal] = useState(false);
    const [modalMsg, setModalMsg] = useState(null);
    const handleModalClose = () => setModal(false);
    const handlePromptModalClose = () => setPromptModal(false);
    
    const { utils: { format: {formatNearAmount, parseNearAmount }}} = nearAPI;

    // Retrieve smart contract details  
	useEffect(async () => {

        // Get smart contract address & details 
        let smartContractID = nearConfig.contractName;
        setContractID(nearConfig.contractName);
        const contractAccount = new nearAPI.Account(near.connection, smartContractID);
        const metadata = await contractAccount.viewFunction(smartContractID, 'get_metadata');
        dispatch(setMetadata(metadata))
        dispatch(setLeftCount(metadata.tokens_minted))
        dispatch(setTotalCount(parseInt(metadata.total_supply)))
        
        // Public & whitelist mint date validation
        let publicDate = new Date(metadata.public_mint_start_time * 1000)
        let whitelistDate = new Date(metadata.wl_mint_start_time * 1000)
        let ogDate = new Date(metadata.og_mint_start_time * 1000)
        setPublicMintDate(publicDate)
        setWhitelistMintDate(whitelistDate)
        setOGMintDate(ogDate)
        setIsPublicMintDate(publicDate.getTime() <= new Date().getTime())
        setIsWhitelistMintDate(whitelistDate.getTime() <= new Date().getTime())
        setIsOGMintDate(ogDate.getTime() <= new Date().getTime())
        
        // Get user wallet details 
        if (isWalletConnected && userAccountId) {
            const userStatus = await contractAccount.viewFunction(smartContractID, 'get_user_status', {"account_id": userAccountId});
            const isWhitelisted = await contractAccount.viewFunction(smartContractID, 'whitelisted', {"account_id": userAccountId});

            setWhitelisted(isWhitelisted);
            dispatch(setWlBalance(userStatus.whilelist));
            dispatch(setMintBalance(userStatus.minted));
            setUserStatus(userStatus);
        }
        else {
            setWhitelisted(false);
        }
	}, [leftCount, userAccountId]);
    
    // Get refund token 
    useEffect(async () => {
        if (isWalletConnected && userAccountId) {
            let smartContractID =  nearConfig.contractName;
            const contractAccount = new nearAPI.Account(near.connection, smartContractID); 
            const mintToken = await contractAccount.viewFunction(smartContractID, 'nft_tokens_for_owner', {"account_id": userAccountId});
            if(mintToken && mintToken.length){
                mintToken.map(async (item, index) => {
                    let refund = await contractAccount.viewFunction(smartContractID, 'get_refund_amount', {"token_id": item.token_id});
                    mintToken[index].refund = formatNearAmount(refund);
                })                    
            }
            setMintedToken(mintToken); 
        }
    }, [isWalletConnected, userAccountId]);
    
    // Hook mint callback function 
    useEffect(() => {
        // Function to check if minted
        async function checkResponse() {
            let url = new URL(window.location.href);
            if(localStorage.getItem('errMsg') != 'null'){
                setModalMsg(localStorage.getItem('errMsg'));
                localStorage.setItem('errMsg', 'null');
            }
            if(localStorage.getItem('minted') == 1){
                setModal(true);        
                localStorage.setItem('minted', null);
                const provider = new nearAPI.providers.JsonRpcProvider(
                    nearConfig.jsonRpcURL
                );
                const result = await provider.txStatus(localStorage.getItem('txHashed'), userAccountId??'lim2481284.near');   
                const log = result.receipts_outcome[0].outcome.logs[0];
                if(log){
                    const token = log.match(new RegExp('token_ids":\\["' + "(.*)" + '"\\]'))[1];
                    if(token) {
                        setTxHashedToken(token);
                    }
                }
            }
            if(localStorage.getItem('burn') == 1){
                showModal('You have burnt your NFT and got a refund successfully');
                localStorage.setItem('burn', null);
            }
            if (url.searchParams.get('errorMessage')) {
                localStorage.setItem('errMsg', url.searchParams.get('errorMessage'));        
            }
            if (url.searchParams.get('mint')) {
                localStorage.setItem('minted', 1);        
                localStorage.setItem('txHashed', url.searchParams.get('transactionHashes'));             
                window.location.replace(window.location.origin);
            }
            if (url.searchParams.get('burn')) {
                localStorage.setItem('burn', 1);        
                window.location.replace(window.location.origin);
            }
        }
        checkResponse();
    }, []);
    

    // If project loaded 
    if(whitelisted !== null && contractID !== null && (!userAccountId || (userAccountId && userStatus !== null))){
        return (
            <>
                <div className="flexSpaceCenter container" id='launchpadContent'>
                    <div id='treeBg' style={{ background : 'url(' + Bg +') center bottom no-repeat'}}>
                    </div>
                    <div id="rightSide">
                        <div id='nearImg'>
                            <img src={C}/>
                            <img src={G} id='nearGIF'/>
                        </div>
                        <div className={(metadata.status == 'Failed')?'hide':'mint-bar'}>
                            <ProgressBar progress={parseFloat(leftCount / totalCount * 100).toFixed(2)} minted={leftCount} total={totalCount}/>
                        </div>
                    </div>
                    <div id="leftSide">
                        <div id='leftSideContainer'>
                            {(metadata.status == 'Failed')
                            ?
                                <span className='status'> Refund </span>
                            :
                                (metadata.status == 'Success')
                                ?
                                    <span className='status'> Ended </span>
                                :
                                    <span className='status'> { isWhitelistMintDate || isPublicMintDate?'Minting':'Coming Soon' } </span>
                            }
                            <h1 className="bold font60 project-title mb-2 mt-4"><img src={Logo}/></h1>
                            <div className='social-item mt-0 mb-0'>
                                <div>
                                    <a target="_blank" rel="noreferrer" className='topSocial' style={{ padding: "15px 25px" }} href='https://neartrees.com/'><img src={Web} alt="website"/></a>
                                    <a target="_blank" rel="noreferrer" className='topSocial' style={{ padding: "15px 25px" }} href='https://twitter.com/NearTreesNFT'><img src={Twitter} alt="twitter"/></a>
                                    <a target="_blank" rel="noreferrer" className='topSocial' href='https://discord.gg/NearTrees'><img src={Discord} alt="discord"/></a>
                                </div>                         
                            </div>        
                            <p className='mt-2 mb-4'>Planting 2555 trees on $Near ☘️
A deflationary collection of Trees with metaverse exposure </p>  
                            <Tabs defaultActiveKey= {(metadata.status == 'Failed')?'burn':'mint'} id="projectTab" className="mb-3">
                                {(metadata.status == 'Failed')
                                ?
                                    <Tab eventKey="burn" title="Refund">
                                        {(!userAccountId)? 
                                            <div id='refundConnect'> 
                                                <button className='btn-primary connectWallet' onClick={() => headerBtnHandle()}>
                                                    Connect Wallet
                                                </button> 
                                            </div>
                                        :
                                        <>
                                            <div className='row'>
                                                {(mintedToken && mintedToken.length)? mintedToken.map((item, index) => (
                                                    <>
                                                        <div className='col-sm-6'>
                                                            <div class='burn-item'>
                                                                <img src={"https://cloudflare-ipfs.com/ipfs/QmZAWrY1YWcaxJ9KRc4FDccCpVfLe9kFTHwRTgSiRDSmwv/" + 1 + ".png"}/>
                                                                <h4> Token :  {item.token_id} </h4>
                                                                <p> Refund :  {parseFloat(item.refund).toFixed(5)} NEAR </p>
                                                                <button className='btn-primary refund-btn' onClick={() => handleRefund(item.token_id)}>
                                                                    Refund
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </>
                                                )):''}
                                            </div>
                                        </>
                                        }
                                    </Tab>
                                :
                                    <Tab eventKey="mint" title="Mint">
                                        <div  className={(isOGMintDate && !isWhitelistMintDate && !isPublicMintDate)?'mint-info-list highlight':'mint-info-list'} >
                                            <span> OG Mint </span>
                                            <p className='mint-info-content'>
                                                Price 
                                                <label>  {formatNearAmount(metadata.og_mint_price)} NEAR</label>
                                            </p>                                          
                                           
                                            <div className='countdown'>
                                                {(isOGMintDate)? 
                                                 <p> 
                                                    {(metadata.status == 'Failed' || metadata.status == 'Success')
                                                    ?
                                                        "Ended" 
                                                    :
                                                        "Ongoing"
                                                    }
                                                </p>
                                                :
                                                    <>
                                                        <p>Start In</p>
                                                        <CountDown type='whitelist' isWhitelist={isOGMintDate} whitelistDate={ogMintDate}/>
                                                    </>
                                                }
                                            </div>
                                            <div className='mint-section'>
                                                {
                                                    (!isWalletConnected)
                                                    ?
                                                        <button className='btn-primary connectWallet' onClick={() => headerBtnHandle()}>
                                                            Connect Wallet
                                                        </button>
                                                    :
                                                        (!isOGMintDate) ?                                             
                                                            <button className='btn-primary connectWallet' disabled>Coming Soon <small> You are allocated with {userStatus.og_whitelist} OG mint </small></button>
                                                        :
                                                        (metadata.status == 'Failed' || metadata.status == 'Success') ?'' :
                                                            (leftCount >=  totalCount)? 
                                                                <button className='btn-primary connectWallet' disabled>Mint Out</button>
                                                            :
                                                                (userStatus.og_whitelist <= 0)?
                                                                    <button className='btn-primary connectWallet' disabled>  
                                                                        Mint Now
                                                                        <small> You are allocated with {userStatus.og_whitelist} OG mint </small>
                                                                    </button> 
                                                                :
                                                                   <button className='btn-primary connectWallet' onClick={() => mint(2)}>  
                                                                    Mint Now
                                                                    <small> You are allocated with {userStatus.og_whitelist} OG mint </small>
                                                                </button>  
                                                }
                                            </div>
                                        </div>
                                        <div  className={(isWhitelistMintDate && !isPublicMintDate)?'mint-info-list highlight':'mint-info-list'} >
                                            <span> Whitelist Mint </span>
                                            <p className='mint-info-content'>
                                                Price 
                                                <label>  {formatNearAmount(metadata.wl_mint_price)} NEAR</label>
                                            </p>
                                            <div className='countdown'>
                                                {(isWhitelistMintDate)? 
                                                <p> 
                                                    {(metadata.status == 'Failed' || metadata.status == 'Success')
                                                    ?
                                                        "Ended" 
                                                    :
                                                        "Ongoing"
                                                    }
                                                </p>
                                                :
                                                    <>
                                                        <p>Start In : </p>
                                                        <CountDown type='whitelist' isWhitelist={isWhitelistMintDate} whitelistDate={whitelistMintDate}/>
                                                    </>
                                                }
                                            </div>
                                            <div className='mint-section'>
                                                {
                                                    (!isWalletConnected)
                                                    ?
                                                        <button className='btn-primary connectWallet' onClick={() => headerBtnHandle()}>
                                                            Connect Wallet
                                                        </button>
                                                    :
                                                        (!isWhitelistMintDate) ?                                             
                                                            <button className='btn-primary connectWallet' disabled>Coming Soon  <small> You are allocated with {wlBalance} WL mint </small></button>
                                                        :
                                                        (metadata.status == 'Failed' || metadata.status == 'Success') ?'' :
                                                            (leftCount >=  totalCount)? 
                                                                <button className='btn-primary connectWallet' disabled>Mint Out</button>
                                                            :
                                                                (!whitelisted || wlBalance <= 0)?
                                                                    <button className='btn-primary connectWallet' disabled>  
                                                                        Mint Now
                                                                        <small> You are allocated with 0 WL mint </small>
                                                                    </button> 
                                                                :
                                                                   <button className='btn-primary connectWallet' onClick={() => mint(1)}>  
                                                                    Mint Now
                                                                    <small> You are allocated with {wlBalance} WL mint </small>
                                                                </button>  
                                                }
                                            </div>
                                        </div>
                                        <div className={isPublicMintDate?'mint-info-list highlight':'mint-info-list'} id='publicMintInfo'>
                                            <span> Public Mint </span>
                                            <p className='mint-info-content'>
                                                Price 
                                                <label>  {formatNearAmount(metadata.mint_price)} NEAR</label>
                                            </p>    
                                            <div className='countdown'>
                                                {(isPublicMintDate)?
                                                    (metadata.status == 'Failed' || metadata.status == 'Success')
                                                    ?
                                                        <p> Ended </p>
                                                    :
                                                       <>
                                                            <p>End In </p>
                                                            <CountDown type='publicEnd'/>
                                                       </>
                                                :
                                                <>
                                                    <CountDown type='public' isPublic={isPublicMintDate} publicDate={publicMintDate}/>
                                                </>
                                                }
                                            </div>
                                            <div className='mint-section'>
                                                {
                                                    (!isWalletConnected)
                                                    ?
                                                        <button className='btn-primary connectWallet' onClick={() => headerBtnHandle()}>
                                                            Connect Wallet
                                                        </button>
                                                    :
                                                        (!isPublicMintDate) ?                                             
                                                            <button className='btn-primary connectWallet' disabled>Coming Soon</button>
                                                        :
                                                        (metadata.status == 'Failed' || metadata.status == 'Success') ?'' :
                                                            (leftCount >=  totalCount)? 
                                                                <button className='btn-primary connectWallet' disabled>Mint Out</button>
                                                            :
                                                                <button className='btn-primary connectWallet' onClick={() => mint()}>
                                                                    Mint Now
                                                                </button>
                                                }                                                
                                            </div>
                                        </div>                                        
                                    </Tab>
                                }
                                <Tab eventKey="roadmap" title="Roadmap">
                                    <div id='roadmapContent'>
                                        <div className='col-12 mb-3'>
                                            <div className='row fa-step'>
                                                <span className='step-icon'>1</span>
                                                <div className='col-12 mb-3'>
                                                    <h3>PHASE 1</h3> 
                                                    <p>Build an active community organically and plant the first seeds of our vision. Moreover, we will collaborate with other projects in order to satisfy the holders expectation.</p> 
                                                    <p>👀 The roots have settled and vision is clear. Rewarding early adopters, we will allocate 333 seeds which will be minted for FREE (FCFS) and used as tickets (OG) for our upcoming NFT project as well as our future projects. Besides that the seeds will have exclusive benefits. </p> 
                                                </div>                                                
                                                <div className='fa-step-line'></div>
                                            </div> 
                                            <div className='row fa-step'>
                                                <span className='step-icon'>2 </span>
                                                <div className='col-12 mb-3'>
                                                    <h3>PHASE 2</h3> 
                                                    <p>Minting is live on our own website. We will reveal the mint price and supply soon.</p> 
                                                    <p>Rarity ranking will be live on our website and Discord/Twitter</p> 
                                                    <p>Sales and listing bots implementation</p> 
                                                    <p>Holder verification system to be able to access TreesDao  </p> 
                                                </div>                                                
                                                <div className='fa-step-line'></div>
                                            </div> 
                                            <div className='row fa-step'>
                                                <span className='step-icon'>3 </span>
                                                <div className='col-12 mb-3'>
                                                    <h3>PHASE 3</h3> 
                                                    <p>10% Charity allocation will be decided together with DAO</p> 
                                                    <p>Merch and exclusive 3D collectibles </p> 
                                                    <p>$TREES token. Who said money doesn’t grow on trees?</p> 
                                                </div>                                                
                                                <div className='fa-step-line'></div>
                                            </div> 
                                            <div className='row fa-step'>
                                                <span className='step-icon'>4 </span>
                                                <div className='col-12 mb-3'>
                                                    <h3>PHASE 4</h3> 
                                                    <p>Metaverse exposure</p> 
                                                    <p>Sandbox land </p> 
                                                    <p>P2E Game</p> 
                                                </div>                                                
                                                <div className='fa-step-line'></div>
                                            </div> 
                                            <div className='row fa-step'>
                                                <span className='step-icon'>5 </span>
                                                <div className='col-12 mb-3'>
                                                    <h3>PHASE 5</h3> 
                                                    <p>Roadmap 2.0</p> 
                                                </div>                                                
                                                <div className='fa-step-line'></div>
                                            </div> 
                                        </div>                                                
                                    </div>
                                </Tab> 
                               
                            </Tabs>   
                        
                        </div>
                        <div className='powered'>
                            <h3> <a href='https://launchpad.enleap.app/'> Powered by - Enleap </a> <img src={Enleap}/> </h3>
                        </div> 
                    </div>
                  
                </div>
                
                <Modal className='custom-modal' show={modal} onHide={handleModalClose} >
                    <Modal.Body>
                    <div style={{ textAlign :"center"}}> 
                            {modalMsg ? 
                                <p className="font20">{ decodeURIComponent(modalMsg) } </p>
                            :
                            <>
                                {
                                    txHashedToken? 
                                        <img src={"https://near-trees.enleap.app/ipfs/" + txHashedToken + ".png"}/>
                                    :''
                                }
                                <p className="font20 mb-3"> Congratulation, you have minted successfully! </p>                
                            </>
                            }
                            <div className="promptBtnSection">
                                <button className="promptClose" onClick={handleModalClose}> ✕  </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal className='custom-modal prompt-modal' show={promptModal} onHide={handlePromptModalClose} >
                    <Modal.Body>
                        <div  className='promptBody'>
                            <div>
                                <p> Always DYOR! Enleap and NTU do not take any responsibility for your investment decision.</p>
                                <button id="promptMintBtn" className='btn btn-primary'  onClick={() => mint()}> Mint Now </button>
                                <div className="promptBtnSection">
                                    <button className="promptClose" onClick={handlePromptModalClose}> ✕  </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
    else 
    {
        return (
            <div id="loader">
                <p>Loading...</p>
            </div>
        )
    }

    
    // FUnction to show moadl 
    function showModal(msg){
        setModalMsg(msg);
        setModal(true);
    }
    

    // Main function to proceed with mint 
    function mint(type = null){

        if (isWalletConnected)
        {
            if(leftCount >= totalCount)
                showModal('Mint Out');
            else 
            {
                if(isOGMintDate || isPublicMintDate || isWhitelistMintDate){

                    // Mint 
                    const ogBalance = userStatus.og_whitelist;
                    nftMintFromContract(wallet.account(), userBalance, 1, wlBalance, mintBalance, metadata, contractID, isWhitelistMintDate, isPublicMintDate, type, ogBalance).then(function(response){
                        if(response != 'success')
                            showModal(response)
                    });

                }
                else 
                    showModal('Coming Soon');
            }
        }
        else 
        {
            wallet.requestSignIn(
				nearConfig.contractName,
				'Enleap',
			);
        }
    }

    // Handle refund
    function handleRefund(token){
        if(metadata.status == 'Failed'){
            burnNFT(wallet.account(), token, contractID).then(function(response){
                if(response != 'success')
                    showModal(response)
            });
        }
        else 
            alert('Invalid action');
    }
    
    // Header button handle 
	function headerBtnHandle(type=null){
		if (isWalletConnected)
        {
            if(leftCount >= totalCount)
                showModal('Mint Out');
            else 
            {
                if(isOGMintDate || isPublicMintDate || isWhitelistMintDate){
                    setPromptModal(true);
                    setMintType(type)
                }
                else 
                    showModal('Coming Soon');
            }
        }
        else 
        {
            wallet.requestSignIn(
				nearConfig.contractName,
				'Enleap',
			);
        }
	}
}

